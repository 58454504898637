<template>
    <SeoMetatags />
    <CookieBanner />
    <!-- <GlobalHeader /> -->
    <main id="main-content">
        <slot />
    </main>
    <!-- <GlobalFooter /> -->
</template>

<script lang="ts" setup>
useHead(() => {
    return {
        title: "Search Page",
        bodyAttrs: {
            class: "detail-page"
        }
    }
})
</script>